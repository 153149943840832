<template>
  <div class="scroll-list">
    <LicenseCreate />
  </div>
</template>

<script>
import LicenseCreate from '@/components/licenses/LicenseCreate'
export default {
  components: {
    LicenseCreate
  }
}
</script>


<style>
</style>
