<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="eier"
            :rules="eierRules"
            :counter="20"
            label="Skole/institusjonsnavn"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="displayDate"
                label="Date"
                hint="Gyldig fra dato"
                persistent-hint
                prepend-icon="mdi-calendar-month-outline"
                v-on="on"
              ></v-text-field>
            </template>
            <!-- @blur="date = parseDate(dateFormatted)" -->
            <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            v-model="institusjonsType"
            :items="items"
            :rules="[v => !!v || 'Institusjonstype må velges']"
            label="Institusjonstype"
            required
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="ressursnummer"
            :counter="20"
            label="Ressursnummer"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="kontaktperson"
            :rules="kontaktpersonRules"
            label="kontaktpersons fulle navn"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="kontaktpersonEpost"
            :rules="emailRules"
            label="kontaktpersons epostadresse"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="adresse" label="Adresse" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="postnummer" label="Postnummer" :rules="postnummerRules" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="sted" label="Sted" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="brukerantall"
            label="Antall brukere"
            :rules="brukerantallRules"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="skolekode" label="Skolekode" :counter="8" required
            :rules="skolekodeRules"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row>
        <v-btn color="error" class="mr-4" @click="reset">Reset Form</v-btn>

        <v-btn :disabled="false" :loading="saving" color="success" class="mr-4" @click="createLicense">Opprett</v-btn>
        <!-- !valid -->
      </v-row>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Opprett lisens feilet</v-card-title>

        <v-card-text>
          Det har oppstått en feil som forhindret å opprette en lisens. Ta en kikk på feilmeldingen nedenfor for mulig å finne årsaken.
          <div>
            <code>{{ feilmelding }}</code>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-container>
  </v-form>
</template>

<script>
import { createLicense } from "@/graphql/mutations";
// import * as queries from "@/graphql/queries";
import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

export const listLicenses = `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

export default {
  data() {
    return {
      saving: false,
      dialog: false,
      feilmelding: true,
      skolekoder: [],
      valid: true,
      // Date form
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,

      // The rest
      eier: "",
      eierRules: [
        v => !!v || "Navn påkrevd",
        v => (v && v.length >= 3) || "Navn må ha minst tre bokstaver"
      ],
      ressursnummer: "",

      institusjonsType: undefined,
      items:  ["ungdomsskole", "vgs", "annen"],

      kontaktperson: "",

      kontaktpersonRules: [
        v => !!v || "Navn påkrevd",
        v => (v && v.length > 3) || "Navnet må være lengre enn tre bokstaver"
      ],

      kontaktpersonEpost: "",
      emailRules: [
        v => !!v || "Epost er påkrevet",
        v => /.+@.+\..+/.test(v) || "E-postadresse må være gyldig"
      ],

      adresse: "",

      postnummer: "",
      postnummerRules: [
        v => !!v || "Postnummer er påkrevet",
        v => /^[0-9]{4}$/.test(v) || "Postnummer må ha 4 siffer"
      ],
      sted: "",

      brukerantall: "", // Int
      brukerantallRules: [
        v => !!v || "Antall er påkrevet",
        v => /^[0-9]*$/.test(v) || "Må være et tall",
        v => v > 0 || "Må være større enn 0"
      ],

      skolekode: "",
      skolekodeRules: [
        v => !!v || "Skolekode er påkrevd",
        v => (v && v.length >= 5 && v.length <= 8) || "Skolekode må bestå av mellom 6 og 8 bokstaver",
        v => (this.skolekodeExists (v)) || "Skolekoden finnes fra før"
      ],

      betalt: false // Boolean
    };
  },
  created () {
    console.log('license created')
    this.getSkolekoder ()
  },
  watch: {
    // date(val) {
    //   this.dateFormatted = this.formatDate(this.date);
    // }
  },
  computed: {
    displayDate () {
      return this.date
    }
  },
  methods: {
    skolekodeExists (code) {
      // console.log('this.skolekoder.indexOf(code)', this.skolekoder.indexOf(code))
      return this.skolekoder.indexOf(code) > -1 ? false : true
    },
    async getSkolekoder () {
      let result
      try {
        result = await API.graphql({
          query: listLicenses,
          variables: {
            limit: 2000
          },
          // auth: "IAM" // API_KEY
        })
      } catch (err) {

         (err)
      }
      // console.log('result.data.listLicenses', JSON.stringify(result.data.listLicenses.items))
      this.skolekoder = result.data.listLicenses.items.map( obj => {
        return obj.id
      })
      // console.log(this.skolekoder)
    },
    async createLicense () {
      const license = {
        id: this.skolekode,
        eier: this.eier,
        gyldigFraDato: this.date,
        aktiv: true,
        ressursnummer: this.ressursnummer,
        institusjonsType: this.institusjonsType,
        kontaktperson: this.kontaktperson,
        kontaktpersonEpost: this.kontaktpersonEpost,
        adresse: this.adresse,
        postnummer: this.postnummer,
        sted: this.sted,
        brukerantall: Number(this.brukerantall), // Int
        betalt: this.betalt
      }
      console.log('this.licenseEdited', JSON.stringify(license))
      this.saving = true
      this.error = ''
      let result;
      try {
        result = await API.graphql({
          query: createLicense,
          variables: {
            input: license
          }
          // auth: "API_KEY"
        })
        this.reset
        this.saving = false

        this.$router.push({ name: 'editLicense', params: {id: result.data.createLicense.id} })
      } catch (err) {
        this.saving = false
        this.feilmelding = err.errors[0] ? err.errors[0].message : err
        console.log("feil:", err.errors);
        this.dialog = true
      }
      console.log(JSON.stringify(result));
    },
    reset() {
      this.$refs.form.reset();
    },
    shortIsoDate(date) {
      if (!date) return null;
        date = date.substr(0, 10)
        const [year, month, day] = date.split("-");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>

<style>
</style>
